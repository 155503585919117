import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`During a lecture a few months back, designer Peter Cocking quipped “I find, if you speak about anything for long enough, `}<strong parentName="p">{`it always comes back to type`}</strong>{`.”`}</p>
    <p>{`All the more reason to speak about it sooner.`}</p>
    <p>{`I’m excited to announce the `}<a parentName="p" {...{
        "href": "http://meetup.com/vancouver-typography-meetup-group"
      }}>{`Vancouver Typography Meetup Group`}</a>{` is now `}<strong parentName="p">{`Type Brigade`}</strong>{`. Our first event with our new name—the group’s 20`}<sup>{`th`}</sup>{` event—will be on June 24th at `}<a parentName="p" {...{
        "href": "https://www.google.com/maps/place/948+Homer+St/@49.2780571,-123.119061,17z"
      }}>{`Mobify HQ`}</a>{`. That’s one floor below where Bringhurst’s classic `}<em parentName="p">{`The Elements of Typographic Style`}</em>{` was published. `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/186884552/"
      }}>{`Register for `}<strong parentName="a">{`Type Brigade`}</strong>{` here.`}</a></p>
    <p>{`Why the name `}<strong parentName="p">{`Type Brigade`}</strong>{`? It signals a change in format: two talks, 25 minutes each, followed by a five minute Q&A. It’s free to attend. Additionally, it’s a commitment to focusing on type design as much as typography.`}</p>
    <p>{`Shelley Gruendler originally created this meetup, and she continues to create amazing opportunities for type designers and typographers—`}<a parentName="p" {...{
        "href": "http://www.typecamp.org/camps/vancouver-pointed-pen"
      }}>{`in Vancouver`}</a>{` and `}<a parentName="p" {...{
        "href": "http://www.typecamp.org/incredible-india-2014"
      }}>{`further away from home`}</a>{`. I want to thank her for all the time she has invested into getting this meetup where it is, and her go-ahead to move it in a new direction.`}</p>
    <p>{`I’m really looking forward to meeting you and—whether you’ve been to an event yet or not—sharing what we have planned.`}</p>
    <p>{`See you at `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/186884552/"
      }}>{`Type Brigade №20`}</a>{` on June 24`}<sup>{`th`}</sup>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      